import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import logo from "./../../images/herstellung-im-einklang-mit-der-natur.png"
import deLogo from "./../../images/flag.png"
import esLogo from "./../../images/spain.png"
import bsLogo from "./../../images/bosnia-and-herzegovina.png"

const Header = () => (
  <header className="is-hidden-touch">
    <div className="container">
      <div className="_inner">
        <div className="columns">
          <div className="column is-narrow _logoLine">
            <div id="_langMenu" className="dropdown is-hoverable">
              <div className="dropdown-trigger">
                <Link to="/">
                  <figure className="icon">
                    <img src={deLogo} alt="Language" />
                  </figure>
                </Link>
              </div>
              <div className="dropdown-menu">
                <div className="dropdown-content">
                  <Link to="/es/" className="dropdown-item animated fadeInUp">
                    Español
                  </Link>
                  <Link
                    to="/bs/"
                    className="dropdown-item animated delay-1s fadeInUp"
                  >
                    Bos/Hr/Srp
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="_mainMenu">
              <div className="columns">
                <div className="column is-narrow">
                  <div className="_leftMenu">
                    <Link
                      to="/inspiration"
                      title="INSPIRATION AUS DER WEISHEIT DER NATUR"
                    >
                      Inspiration
                    </Link>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div id="_logoMenu" className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <Link to="/">
                        <h1
                          className="_logo title"
                          title="IMMMUNO NATURA - Nahrungsergänzungsmittel und Kosmetika"
                        >
                          IMMMUNO NATURA
                        </h1>
                      </Link>
                    </div>
                    <div className="dropdown-menu">
                      <div className="dropdown-content">
                        <Link
                          to="/uber-uns"
                          title="ÜBER UNS UND PRINZIPIEN UNSERER ARBEIT"
                          className="dropdown-item animated fadeInUp"
                        >
                          Über uns
                        </Link>
                        <Link
                          to="/partnerschaft"
                          title="PARTNER WERDEN"
                          className="dropdown-item animated delay-1s fadeInUp"
                        >
                          Partnerschaft
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="_rightMenu">
                    <Link
                      to="/produkte"
                      title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                    >
                      Produkte
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-narrow _contact">
            <Link to="/kontakt" title="KONTAKTIERE UNS!">
              Kontakt
            </Link>
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
